import React from "react"
import { Helmet } from "react-helmet"
import { t, currentLanguage, Language } from "../i18n"
import Question from "../question"

export default () => (
  <>
    <Helmet>
      <meta property="og:url" content="https://moondisaster.org/question" />
      <meta property="og:title" content={t("question.meta.title")} />
      <meta
        property="og:description"
        content={t("question.meta.description")}
      />
      <meta
        property="og:image:secure_url"
        content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x630-cropped.jpg"
      />
      <meta
        property="og:image:url"
        content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x630-cropped.jpg"
      />
      <meta
        property="og:image"
        content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x630-cropped.jpg"
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content={t("question.meta.imageAlt")} />
      <meta
        property="article:author:name"
        content={t("question.meta.authorName")}
      />
      <meta name="twitter:title" content={t("question.meta.title")} />
      <meta
        name="twitter:description"
        content={t("question.meta.description")}
      />
      <meta name="twitter:creator" content={t("question.meta.authorName")} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:image"
        content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x854.jpg?p=1806"
      />
      <meta name="twitter:image:alt" content={t("question.meta.imageAlt")} />
      <meta itemProp="name" content={t("question.meta.title")} />
      <meta itemProp="description" content={t("question.meta.description")} />
      <meta name="author" content={t("question.meta.authorName")} />
      <meta name="description" content={t("question.meta.description")} />
      <meta
        name="thumbnail"
        content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x630-cropped.jpg"
      />
    </Helmet>
    <Question />
  </>
)
